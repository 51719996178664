<template>
    <div v-loading.fullscreen.lock="loading">
        <div v-for="businessInfoId in businessInfoIds" :key="businessInfoId" class="py-12 border-b">
            <div class="text-center text-2xl font-bold">
                {{ getBusinessName(businessInfoId) }}
            </div>
            <business-close-day :businessInfoId="businessInfoId"></business-close-day>
        </div>
    </div>
</template>
<script>
import error from '@/mixins/error';
import OrderService from '@/services/OrderService';

export default {
    name: 'GeneralCloseDay',
    mixins: [error],
    components: {
        BusinessCloseDay: () => import('./BusinessCloseDay'),
    },
    data() {
        return {
            loading: false,
            businessInfoIds: [],
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const data = await OrderService.getDailySales({
                    date: this.$moment(new Date()).format('MM/DD/YYYY'),
                });
                this.businessInfoIds = [
                    ...new Set([
                        ...data.totalPaymentMethod.flatMap(({ orders }) => orders.map(({ businessInfoId }) => businessInfoId)).filter(id => id !== undefined),
                        ...data.totalExpenseMethod.flatMap(({ expenses }) => expenses.map(({ businessInfoId }) => businessInfoId)).filter(id => id !== undefined),
                    ]),
                ];
            } catch (error) {
                const errorMessage = this.getErrorMessage(error);
                this.$toastr.e(errorMessage);
            } finally {
                this.loading = false;
            }
        },
        getBusinessName(businessInfoId) {
            const business = this.$store.state.business.offices.find(({ id }) => id === businessInfoId);
            if (business) {
                return business.name;
            }
            return '';
        }
    },
}
</script>